// .form-group .label
$form-group-label-font-weight: 600;
$form-group-label-text-transform: capitalize;
$form-group-label-color: #222;
$form-group-label-line-height: 21px;
$form-group-label-font-size: 14px;
$form-group-label-margins: (
  "bottom": 5px
);

// .form-group.label-large .label
$form-group-label-large-label-font-size: 14px;

// .form-group .form-control
$form-control-placeholder-font-style: normal;
$form-control-border-color: #bbb;
$form-control-border-radius: 8px;
$form-control-height: 48px;
$form-control-padding: 9px;
$form-control-font-size: 14px;
$form-control-line-height: 30px;
$form-control-background: #fff;
$form-control-disabled-background: #eeeeee;

// .form-group .form-control::placeholder
$form-control-placeholder-font-style: italic;
$form-control-placeholder-text-transform: capitalize;
